import { Form, submit, useDispatch } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '../../../../components/base/Button';
import CircularProgress from '../../../../components/base/CircularProgress';
import GridSplit from '../../../../components/base/GridSplit';
import IconDelete from '../../../../components/icons/Delete';
import IconSave from '../../../../components/icons/Save';
import AsideBody from '../../../../components/Space/Aside/Body';
import userData from '../../../../objects/users/datas.json';
import {
  usersDelete as deleteMutation,
  usersUpdate as updateMutation,
} from '../../../../objects/users/mutations';
import userQuery from '../../../../objects/users/queries';
import UserType from '../../../../types/User';
import requiredAuth from '../../../../utils/requireAuth';

const UserPage: FC<PageProps> = ({ params: { id } }) => {
  const formName = 'user';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateMutationFunc] = useMutation(updateMutation);
  const [deleteMutationFunc] = useMutation(deleteMutation);
  const { data, loading, refetch } = useQuery(userQuery, {
    variables: {
      id,
    },
  });

  const user: UserType = data && (data.user as UserType);

  if (!user) {
    return <CircularProgress fullPage />;
  }

  const handleUserDeleteOnClick = async () => {
    const ok = window.confirm(
      'Etes-vous sûr de vouloir supprimer cet usager ?',
    );

    if (ok) {
      const result = await deleteMutationFunc({
        variables: {
          id,
        },
      });

      if (!result || result.errors) {
        console.error(result.errors);
      } else {
        window.history.back();
      }
    }
  };

  const handleSaveOnClick = () => {
    dispatch(submit(formName));
  };

  const handleOnSubmit = async (values: any) => {
    const result = await toast.promise(
      updateMutationFunc({
        variables: {
          ...values,
          id,
        },
      }),
      {
        error: "Erreur lors de la création d'un usager",
        pending: "Création en cours d'un usager",
        success: "Création d'un usager réussie",
      },
    );

    if (result.errors) {
      toast.error(result.errors.map(e => e.message).join(', '));
    } else {
      refetch();
    }
  };

  return (
    <GridSplit
      aside={{
        body: (
          <AsideBody
            places={
              user.address
                ? [{ formattedAddress: user.address, name: user.name }]
                : []
            }
          />
        ),
        className: 'sticky top-0 self-start',
        width: '50%',
      }}
      header={{
        actions: (
          <div className="flex space-x-3">
            <Button
              iconLeft={IconSave}
              onClick={handleSaveOnClick}
              size="small"
              title={t('user.actions.submit.update')}
            />
            <Button
              iconLeft={IconDelete}
              onClick={handleUserDeleteOnClick}
              size="small"
              status="danger"
              title={t('user.actions.delete.title')}
            />
          </div>
        ),
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: user.name,
      }}
    >
      {loading && <CircularProgress />}
      <Form
        className="mx-6 mb-6"
        datas={userData}
        hideSubmitButton
        initialValues={user}
        name={formName}
        onSubmit={handleOnSubmit}
        submitProps={{
          label: t(`user.form.submit.label`),
          size: 'small',
        }}
      />
    </GridSplit>
  );
};

export default requiredAuth(UserPage, {
  admin: true,
  layout: true,
});
