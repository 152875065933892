import gql from 'graphql-tag';

const user = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      name
      companyName
      address
      phone
      email
    }
  }
`;

export default user;
